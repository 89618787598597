<template>
  <div class="app">
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">
          {{ isEdit ? "Edit" : "Create" }} Projects
        </h4>
      </CCol>

      <CCol sm="8"></CCol>
    </CRow>
    <CCard class="p-3">
      <CRow>
        <CCol>
          <div v-if="serverErrors.length">
            <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
              <ul>
                <li v-for="err in serverErrors" :key="err">{{ err }}</li>
              </ul>
            </CAlert>
          </div>
          <form v-on:submit.prevent="isEdit ? onUpdated() : onSubmitted()">
            <div class="row">
              <div class="col-md-6 col-6">
                <div class="form-group">
                  <label>Name</label>
                  <input
                      type="text"
                      class="form-control"
                      v-model="project.name"
                      placeholder="Enter project name"
                      required
                  />
                </div>
              </div>
              <div class="col-md-3 col-3 d-none">
                <div class="form-group">
                  <label>Total Meters</label>
                  <input
                      readonly
                      type="number"
                      class="form-control"
                      v-model="getTotalMeter"
                      placeholder="Enter total meters"
                      required
                  />
                </div>
              </div>

              <div class="col-md-6 col-3">
                <div class="form-group">
                  <label>Deadline</label>
                  <el-date-picker
                      required
                      class="w-100 p-0"
                      v-model="project.deadline"
                      type="date"
                      placeholder="Enter Deadline"
                      :format="$datePickerFormat"
                      value-format="yyyy-MM-dd"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Supervisor</label>
                  <multiselect
                      v-model="project.supervisor"
                      :options="supervisorUsersList"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="Select Supervisor"
                      :custom-label="fullName"
                      label="first_name"
                      track-by="id"
                      :preselect-first="false"
                  >
                    <template
                        slot="selection"
                        slot-scope="{ values, search, isOpen }"
                    ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                    >{{ values.length }} options selected</span
                    ></template
                    >
                  </multiselect>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Project Lead</label>
                  <multiselect
                      v-model="project.project_lead"
                      :options="teamLeadUsersList"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="Select Project Lead"
                      :custom-label="fullName"
                      label="first_name"
                      track-by="id"
                      :preselect-first="false"
                  >
                    <template
                        slot="selection"
                        slot-scope="{ values, search, isOpen }"
                    ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                    >{{ values.length }} options selected</span
                    ></template
                    >
                  </multiselect>
                </div>
              </div>
              <div class="col-md-12 col-12">
                <div class="form-group">
                  <label>Description</label>
                  <textarea
                      type="text"
                      class="form-control"
                      v-model="project.description"
                      placeholder="Enter Description"
                  />
                </div>
              </div>

              <div class="col-12">
                  <table class="table table-bordered w-100">
                      <thead>
                      <tr>
                          <th>Meter Size</th>
                          <th>Quantity</th>
                          <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(size_wise_meter, index) in project.size_wise_meters">
                          <td>
                              <div class="form-group">
                                  <select class="form-control" required v-model="size_wise_meter.meter_size" id="">
                                      <option value="">Please select meter size</option>
                                    <option v-for="(meter_size, key) in meterSizes" :value="key">{{meter_size}}</option>
                                  </select>
                              </div>
                          </td>
                          <td>
                              <div class="form-group">
                                  <input
                                          type="number"
                                          class="form-control"
                                          v-model="size_wise_meter.quantity"
                                          placeholder="Enter Quantity"
                                          required
                                  />
                              </div>
                          </td>
                          <td>
                              <button v-show="project.size_wise_meters.length !==1" @click="remove(index)"
                                      class="btn btn-danger" type="button"><i class="glyphicon glyphicon-plus"></i>
                                  <i class="fa fa-trash"></i>
                              </button>
                          </td>
                      </tr>
                      </tbody>
                  </table>
                  <button @click="addMore" class="float-right btn btn-success add-more btn-sm" type="button">
                      <i class="fa fa-plus"></i>
                      Add more
                  </button>
              </div>
            </div>
            <button type="submit" class="btn btn-primary">{{ isEdit ? "Update" : "Submit" }}</button>
            <router-link :to="{name:'Projects'}" class="btn btn-warning text-white ml-2">Back</router-link>
          </form>
        </CCol>
      </CRow>
    </CCard>
  </div>
</template>

<script>
import Multiselect  from "vue-multiselect";
import {mapGetters} from "vuex";

export default {
  name: "CreateProject",
  data: () => {
    return {
      isEdit              : false,
      breadcrumbs         : [
        {text: "Home", path: "/dashboard", isActive: false},
        {text: "Projects", path: "/projects", isActive: false},
        {text: "Create Project", path: ``, isActive: true}
      ],
      showToast           : false,
      toastr              : "",
      project             : {
        name            : "",
        description     : "",
        project_lead    : "",
        supervisor      : "",
        total_meters    : "",
        size_wise_meters: [
          {
            meter_size: "",
            quantity  : ""
          }
        ],
        deadline        : ""

      },
      serverErrors        : [],
      showDismissibleAlert: false,
    };
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  components: {
    Multiselect,
  },
  methods   : {
    remove(index) {
      this.project.size_wise_meters.splice(index, 1)
    },
    addMore() {
      this.project.size_wise_meters.push({
        meter_size: "",
        quantity  : ""
      })
    },
    fullName({first_name, last_name}) {
      return `${first_name} ${last_name}`;
    },
    onUpdated() {
      this.project.project_lead_id = this.project.project_lead ? this.project.project_lead.id : "";
      this.project.supervisor_id   = this.project.supervisor ? this.project.supervisor.id : "";
      this.project.total_meters    = this.getTotalMeter
      this.$store
          .dispatch("Projects/updateProject", this.project)
          .then(() => {
            this.showToast = true;
            this.$toastr.s('success', "Project successfully updated.", "Project Updated");
            setTimeout(() => {
              this.$router.push({name: 'Projects'})
            }, 500);
          })
          .catch((err) => {
            this.showDismissibleAlert = true;
            this.serverErrors         = [];
            if (err.response.data.message) {
              this.serverErrors.push(err.response.data.message);
            }

            let errors = err.response.data.errors;
            for (let field of Object.keys(errors)) {
              this.showToast = true;
              this.$toastr.e("error, " + errors[field][0] + ", Error!");
            }
          });
    },
    onSubmitted() {
      this.project.project_lead_id = this.project.project_lead ? this.project.project_lead.id : "";
      this.project.supervisor_id   = this.project.supervisor ? this.project.supervisor.id : "";
      this.project.total_meters    = this.getTotalMeter
      this.$store
          .dispatch("Projects/storeProject", this.project)
          .then(() => {
            this.showToast = true;
            this.$toastr.s(
                "success",
                "Project successfully created.",
                "Project Created"
            );
            setTimeout(() => {
              this.$router.push({name: 'Projects'})
            }, 500);
          })
          .catch((err) => {
            this.showDismissibleAlert = true;
            this.serverErrors         = [];
            if (err.response.data.message) {
              this.$toastr.e("error", err.response.data.message, "Error!");
              this.serverErrors.push(err.response.data.message);
            }

            let errors     = err.response.data.errors;
            this.showToast = true;
            for (let field of Object.keys(errors)) {
              this.$toastr.e("error", errors[field][0], "Error!");
            }
          });
    },
  },
  computed  : {
    ...mapGetters("Users", ["supervisorUsersList", "teamLeadUsersList"]),
    ...mapGetters("Constant", ["meterSizes"]),
    getTotalMeter() {
      if (!this.project.size_wise_meters && !Array.isArray(this.project.size_wise_meters)) {
        return 0;
      }
      return this.project.size_wise_meters.reduce((sum, {quantity}) => sum + Number(quantity), 0)
    }
  },
  mounted() {
    this.isEdit = false
    this.$store.dispatch("Constant/getMeterSizes", 'meter_sizes');
    this.$store.dispatch("Users/getUsersList", 'supervisor');
    this.$store.dispatch("Users/getUsersList", 'team_lead');
    if (this.$route.params.id) {
      this.$store.dispatch("Projects/getProject", this.$route.params.id);
      this.isEdit              = true
      this.breadcrumbs[2].text = "Edit Project"
      setTimeout(() => {
        this.project = this.$store.state.Projects.project
      }, 1000)
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
